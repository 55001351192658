export default function Gallery({ data, isMobile }) {
    if (typeof data !== 'undefined') {
            
            return (
            <div id="workshop-gallery">
                {data.galleryitem.map(({title,url,active,id} )=>
                    <div className="text-center">     
                        <h2> {title}</h2>
                        <div id={id}>
                            {url && active?<iframe title={title} src={url} style={{width: '80vw', height: '300px', border: 0}}></iframe>:<span>link not working prperly</span>}
                        </div> 
                    </div>
                        )}
                    
            </div>
            
            // String(JSON.stringify(data))
        );
    } else {
        return null;
    }
}

//   