export default function Footer({ data, isMobile }) {
    return (

        <div>
          <br /><br />
          <div className="footer">
            <div className="row text-center">
            <div className="col-2  mx-auto ">
                        
                    </div>
                    {/* data[0] represents selecting the component workshop.logos */}
              {data[0].image.map(({id,url})=> 
                    <div className="col-2  mx-auto ">
                        <img src={url} style={{width: '8vw'}} alt={`logo-${id}`}/>
                    </div>
                )}
                <div className="col-2  mx-auto ">
                        
                    </div>
            </div>
          </div>
        </div>
        // String(JSON.stringify(data[0].image[0].url))
      );
    }