export default function Program({ data, isMobile }) {

    if (typeof data !== 'undefined') {
        const groupedProgram = data.programdict.reduce((acc, item) => {
            const date = item.date;
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(item);
            return acc;
          }, {});
          const sortedProgram = Object.keys(groupedProgram)
          .map(key => ({ date: key, program: groupedProgram[key] }))
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .reduce((acc, curr) => {
            acc[curr.date] = curr.program;
            return acc;
          }, {});

          
          return (
            <div id="workshop-program ">
              <h1 id="participants">Program</h1>
            <table className="mx-auto">
              <tbody className="text-center">
                {Object.entries(sortedProgram).map(([date, programItems]) => (
                  <>
                    <tr>
                      <td> </td>
                      <td colSpan={6} style={{color: '#ab008e', textAlign: 'center', fontSize: '20px', fontWeight: 'bold'}} valign="middle">
                        {new Date(date).toLocaleDateString("en-US", {year:"numeric", month:"long", day:"numeric"})}</td>
                    </tr>
                    {programItems.map(({title, speaker, starttime, endtime, link, url}) => (
                      <tr>
                        <td align="center" bgcolor="#fffbdd" style={{color: '#78004e'}} valign="middle" width={117}>{starttime.substring(0,5)} –{endtime.substring(0,5)}</td>
                        <td colSpan={6} style={{color: '#393573', paddingLeft: '10px'}} valign="middle">{speaker}{link && url ? <a href={url} target="_blank" rel="noreferrer">{title}</a>:title}</td>
                      </tr>
                    ))}
                  </>
                ))}
              </tbody>
            </table>          
            </div>
            // String(JSON.stringify(data))
        );
    } else {
        return null;
    }
}

//   {"__component":"workshop.program","id":1,"programdict":[{"id":1,"dict":null,"title":"Aditya-L1 Mission","time":null,"date":"2023-02-25","link":true,"url":"https://drive.google.com/file/d/1sIyxICNUyM-2dS3adXqyDKKvyXhmV0cB/view?usp=sharing","speaker":"Prof Dipankaar","starttime":"09:30:00.000","endtime":"10:00:00.000"},{"id":2,"dict":null,"title":"Aditya-L1 Support Cell","time":null,"date":"2023-01-25","link":true,"url":"https://drive.google.com/file/d/1sIyxICNUyM-2dS3adXqyDKKvyXhmV0cB/view?usp=sharing","speaker":"Dr Sankar","starttime":"02:30:00.000","endtime":"03:30:00.000"}]}
