
function SOC({ data, isMobile }) {
  if(typeof data !== 'undefined'){
      return (
        <div>
          <div className="py-2" />
          <div>
            <h2>SCIENTIFIC ORGANISING <span>COMMITTEE</span></h2>
            <br />
            <div className="row">
              {data.SOClist.map((member)=>
                <div className="col-lg-4">
                  <p>{member.Name}<br /><span style={{fontStyle: 'italic', color: '#686868', fontSize: '18px'}}>({member.Affiliation})</span></p>
                </div>
              )}
            </div>
            <br /> 
          </div>
        </div>
        // String(JSON.stringify(data))
      );
  } else {
      return null;
  }
}

export default SOC;
