import React from "react";
import { Button, Popover } from "antd";
import { DownOutlined } from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";

class Banner extends React.PureComponent {
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    return (
      <div
        {...currentProps}
        className="banner0"
        style={{ backgroundImage: `url(${dataSource.coverImage.url})` }}
      >
		
        <Popover
          className="img-description"
          placement="topRight"
          content={
            <p
              className="banner0-caption"
              dangerouslySetInnerHTML={{
                __html: dataSource.imageCaption,
              }}
            ></p>
          }
          trigger="click"
        >
			
          <Button type="link">What's in this image?</Button>
		  </Popover>
		  
		
        <div className="institute-logos">
          <a href="https://www.isro.gov.in/" target="_blank" ><img src="isro_logo.png" width="65px" alt="isro_logo" /></a>
          <a href="https://aries.res.in/" target="_blank" ><img src="aries_logo.png" width="80px" alt="aries_logo" /></a>
          
        </div>

        <QueueAnim
          key="QueueAnim"
          type={["bottom", "top"]}
          delay={200}
          className="banner0-text-wrapper"
        >
          {/* <div className="banner0-byline">
            A joint effort of
            <br />
            <img src="isro_logo.png" alt="isro_logo" />
            &nbsp;&nbsp;&nbsp;
            <img src="aries_logo.png" alt="aries_logo" className="aries" />
          </div> */}

          
          <div
            className="banner0-title">
              {dataSource.title}
            </div>
          <div key="content" className="banner0-content">
            {dataSource.subtitle}
          </div>
         
          <table>
                <tr>
				
                    <td>{/* <Button ghost key="button" href="#about" className="banner0-button">Learn More</Button> */}
                        <Button ghost key="button" href="https://al1ssc.aries.res.in/workshop-5" className="banner0-button blink">
                        Past AL1SC Workshops 
	                </Button>
                    </td>
					
                </tr>
				
                <tr height="50px">
                <td>
                <a href="https://www.isro.gov.in/Aditya_L1.html" target="_blank" rel="noopener noreferrer">
                <img src="https://al1ssc-cms-media.s3.ap-south-1.amazonaws.com/Aditya_L1_logo_faf47e45c3.png" alt="Example Image"/>
                </a>
                </td>
                </tr>
				
                <tr>
                    <td>{/* <Button ghost key="button" href="#about" className="banner0-button">Learn More</Button> */}
                        <Button ghost key="button" href="https://al1ssc.aries.res.in/Events" className="banner0-button blink">
                        Upcoming Events
                        </Button>
                    </td>
                </tr>
            </table> 
			
        </QueueAnim>
        <TweenOne
          animation={{
            y: "-=20",
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
          <DownOutlined />
        </TweenOne>
      </div>
    );
  }
}
export default Banner;
