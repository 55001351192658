import React from 'react'
import { Input, Space, Collapse, Typography,Col ,Row } from 'antd'
const { Panel } = Collapse;
const Title =Typography;

const liStyle = {
  fontFamily: "Arial",
  margin:15

};

const ReviewPapers = () => {
  return (
    <>  
        <Title style={ {  textAlign: 'center', margin:'10px 0' }} ><h4>Space Science Review Papers related to the Sun </h4></Title>

        <Title style={ {  textAlign: 'center', margin:'10px 0' }} ><h5>Category Wise </h5></Title>
        <Collapse accordion defaultActiveKey={['1']} >
        <Panel header="Interior Sun" key="1">        
        <ol>
            <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2022LRSP...19....3H/abstract" target="_blank"> Hanasoge, S. M., “Surface and interior meridional circulation in the Sun”, <i>Living Reviews in Solar Physics</i>, vol. 19, no. 1, 2022. doi:10.1007/s41116-022-00034-7.

                </a>
            </li>
            <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2021LRSP...18....5F/abstract" target="_blank">Fan, Y., “Magnetic fields in the solar convection zone”, <i>Living Reviews in Solar Physics</i>, vol. 18, no. 1, 2021. doi:10.1007/s41116-021-00031-2.

                </a>
                
            </li>
            <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2021LRSP...18....2C/abstract" target="_blank"> Christensen-Dalsgaard, J., “Solar structure and evolution”, <i>Living Reviews in Solar Physics</i>, vol. 18, no. 1, 2021. doi:10.1007/s41116-020-00028-3.

            </a>
                
            </li> 

            <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2017LRSP...14....4B/abstract" target="_blank">Brun, A. S. and Browning, M. K., “Magnetism, dynamo action and the solar-stellar connection”, <i>Living Reviews in Solar Physics</i>, vol. 14, no. 1, 2017. doi:10.1007/s41116-017-0007-8.
            </a>
            </li> 


        </ol> 


        </Panel>

        <Panel header="Solar Photosphere" key="2">
        <ol>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2018LRSP...15....6R/abstract" target="_blank">
        Rincon, F. and Rieutord, M., “The Sun's supergranulation”, <i>Living Reviews in Solar Physics</i>, vol. 15, no. 1, 2018. doi:10.1007/s41116-018-0013-5.
        </a>
        </li> 

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2016LRSP...13....1A/abstract" target="_blank">
        Allende Prieto, C., “Solar and stellar photospheric abundances”, <i>Living Reviews in Solar Physics</i>, vol. 13, no. 1, 2016. doi:10.1007/s41116-016-0001-6.
        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2012LRSP....9....4S/abstract" target="_blank">
        Stein, R. F., “Solar Surface Magneto-Convection”, <i>Living Reviews in Solar Physics</i>, vol. 9, no. 1, 2012. doi:10.12942/lrsp-2012-4.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2010LRSP....7....2R/abstract" target="_blank">
        Rieutord, M. and Rincon, F., “The Sun's Supergranulation”, <i>Living Reviews in Solar Physics</i>, vol. 7, no. 1, 2010. doi:10.12942/lrsp-2010-2.
        </a>
        </li>
        </ol>
        </Panel>

        <Panel header="Solar Corona" key="3">

        <ol>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2020SSRv..216..117A/abstract" target="_blank">
        Antonucci, E., Harra, L., Susino, R., and Telloni, D., “Observations of the Solar Corona from Space”, <i>Space Science Reviews</i>, vol. 216, no. 8, 2020. doi:10.1007/s11214-020-00743-1.
        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2019LRSP...16....2M/abstract" target="_blank">
        Madjarska, M. S., “Coronal bright points”, <i>Living Reviews in Solar Physics</i>, vol. 16, no. 1, 2019. doi:10.1007/s41116-019-0018-8.
        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2018LRSP...15....5D/abstract" target="_blank">
        Del Zanna, G. and Mason, H. E., “Solar UV and X-ray spectral diagnostics”, <i>Living Reviews in Solar Physics</i>, vol. 15, no. 1, 2018. doi:10.1007/s41116-018-0015-3.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2015LRSP...12....7P/abstract" target="_blank">
        Poletto, G., “Solar Coronal Plumes”, <i>Living Reviews in Solar Physics</i>, vol. 12, no. 1, 2015. doi:10.1007/lrsp-2015-7.
        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2015LRSP...12....2L/abstract" target="_blank">
        Laming, J. M., “The FIP and Inverse FIP Effects in Solar and Stellar Coronae”, <i>Living Reviews in Solar Physics</i>, vol. 12, no. 1, 2015. doi:10.1007/lrsp-2015-2.
        </a>
        </li>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2011LRSP....8....5A/abstract" target="_blank">
        Aschwanden, M. J., “Solar Stereoscopy and Tomography”, <i>Living Reviews in Solar Physics</i>, vol. 8, no. 1, 2011. doi:10.12942/lrsp-2011-5.
        </a>
        </li>
        </ol>
        </Panel>


        <Panel header="Solar Magnetic Field" key="4">

       <ol>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2021LRSP...18....1W/abstract" target="_blank">
        Wiegelmann, T. and Sakurai, T., “Solar force-free magnetic fields”, <i>Living Reviews in Solar Physics</i>, vol. 18, no. 1, 2021. doi:10.1007/s41116-020-00027-4.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2019LRSP...16....1B/abstract" target="_blank">
        Bellot Rubio, L. and Orozco Suárez, D., “Quiet Sun magnetic fields: an observational view”, <i>Living Reviews in Solar Physics</i>, vol. 16, no. 1, 2019. doi:10.1007/s41116-018-0017-1.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2015LRSP...12....5P/abstract" target="_blank">
        Petrie, G. J. D., “Solar Magnetism in the Polar Regions”, <i>Living Reviews in Solar Physics</i>, vol. 12, no. 1, 2015. doi:10.1007/lrsp-2015-5.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2013LRSP...10....5O/abstract" target="_blank">
        Owens, M. J. and Forsyth, R. J., “The Heliospheric Magnetic Field”, <i>Living Reviews in Solar Physics</i>, vol. 10, no. 1, 2013. doi:10.12942/lrsp-2013-5.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2012LRSP....9....6M/abstract" target="_blank">
        Mackay, D. H. and Yeates, A. R., “The Sun's Global Photospheric and Coronal Magnetic Fields: Observations and Models”, <i>Living Reviews in Solar Physics</i>, vol. 9, no. 1, 2012. doi:10.12942/lrsp-2012-6.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2012LRSP....9....5W/abstract" target="_blank">
        Wiegelmann, T. and Sakurai, T., “Solar Force-free Magnetic Fields”, <i>Living Reviews in Solar Physics</i>, vol. 9, no. 1, 2012. doi:10.12942/lrsp-2012-5.

        </a>
        </li>
        </ol>
        </Panel>

        <Panel header="Active Region" key="5">
        <ol>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2015LRSP...12....1V/abstract" target="_blank">
        van Driel-Gesztelyi, L. and Green, L. M., “Evolution of Active Regions”, <i>Living Reviews in Solar Physics</i>, vol. 12, no. 1, 2015. doi:10.1007/lrsp-2015-1.

        </a>
        </li>
        </ol>
        </Panel>

        <Panel header="Solar Cycle & Sunspot" key="6">
        <ol>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2023LRSP...20....3K/abstract" target="_blank">
        Karak, B. B., “Models for the long-term variations of solar activity”, <i>Living Reviews in Solar Physics</i>, vol. 20, no. 1, 2023. doi:10.1007/s41116-023-00037-y.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2023LRSP...20....2U/abstract" target="_blank">
        Usoskin, I. G., “A history of solar activity over millennia”, <i>Living Reviews in Solar Physics</i>, vol. 20, no. 1, 2023. doi:10.1007/s41116-023-00036-z.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2022LRSP...19....2C/abstract" target="_blank">
        Cliver, E. W., Schrijver, C. J., Shibata, K., and Usoskin, I. G., “Extreme solar events”, <i>Living Reviews in Solar Physics</i>, vol. 19, no. 1, 2022. doi:10.1007/s41116-022-00033-8.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2020LRSP...17....4C/abstract" target="_blank">
        Charbonneau, P., “Dynamo models of the solar cycle”, <i>Living Reviews in Solar Physics</i>, vol. 17, no. 1, 2020. doi:10.1007/s41116-020-00025-6.

        </a>
        </li>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2020LRSP...17....2P/abstract" target="_blank">
        Petrovay, K., “Solar cycle prediction”, <i>Living Reviews in Solar Physics</i>, vol. 17, no. 1, 2020. doi:10.1007/s41116-020-0022-z.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2020LRSP...17....1A/abstract" target="_blank">
        Arlt, R. and Vaquero, J. M., “Historical sunspot records”, <i>Living Reviews in Solar Physics</i>, vol. 17, no. 1, 2020. doi:10.1007/s41116-020-0023-y.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2023LRSP...20....2U/abstract" target="_blank">
        Usoskin, I. G., “A history of solar activity over millennia”, <i>Living Reviews in Solar Physics</i>, vol. 20, no. 1, 2023. doi:10.1007/s41116-023-00036-z.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2015LRSP...12....6K/abstract" target="_blank">
        Khomenko, E. and Collados, M., “Oscillations and Waves in Sunspots”, <i>Living Reviews in Solar Physics</i>, vol. 12, no. 1, 2015. doi:10.1007/lrsp-2015-6.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2015LRSP...12....4H/abstract" target="_blank">
        Hathaway, D. H., “The Solar Cycle”, <i>Living Reviews in Solar Physics</i>, vol. 12, no. 1, 2015. doi:10.1007/lrsp-2015-4.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2013LRSP...10....1U/abstract" target="_blank">
        Usoskin, I. G., “A History of Solar Activity over Millennia”, <i>Living Reviews in Solar Physics</i>, vol. 10, no. 1, 2013. doi:10.12942/lrsp-2013-1.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2011LRSP....8....3R/abstract" target="_blank">
        Rempel, M. and Schlichenmaier, R., “Sunspot Modeling: From Simplified Models to Radiative MHD Simulations”, <i>Living Reviews in Solar Physics</i>, vol. 8, no. 1, 2011. doi:10.12942/lrsp-2011-3.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2011LRSP....8....4B/abstract" target="_blank">
        Borrero, J. M. and Ichimoto, K., “Magnetic Structure of Sunspots”, <i>Living Reviews in Solar Physics</i>, vol. 8, no. 1, 2011. doi:10.12942/lrsp-2011-4.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2010LRSP....7....1H/abstract" target="_blank">
        Hathaway, D. H., “The Solar Cycle”, <i>Living Reviews in Solar Physics</i>, vol. 7, no. 1, 2010. doi:10.12942/lrsp-2010-1.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2010LRSP....7....6P/abstract" target="_blank">
        Petrovay, K., “Solar Cycle Prediction”, <i>Living Reviews in Solar Physics</i>, vol. 7, no. 1, 2010. doi:10.12942/lrsp-2010-6.
        </a>
        </li>

        </ol>
   
        </Panel>

        <Panel header="Solar Flare" key="7">
        <ol>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2019LRSP...16....3T/abstract" target="_blank">
        Toriumi, S. and Wang, H., “Flare-productive active regions”, <i>Living Reviews in Solar Physics</i>, vol. 16, no. 1, 2019. doi:10.1007/s41116-019-0019-7.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2017LRSP...14....2B/abstract" target="_blank">
        Benz, A. O., “Flare Observations”, <i>Living Reviews in Solar Physics</i>, vol. 14, no. 1, 2017. doi:10.1007/s41116-016-0004-3.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2011LRSP....8....6S/abstract" target="_blank">
        Shibata, K. and Magara, T., “Solar Flares: Magnetohydrodynamic Processes”, <i>Living Reviews in Solar Physics</i>, vol. 8, no. 1, 2011. doi:10.12942/lrsp-2011-6.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2010LRSP....7....4O/abstract" target="_blank">
        Ofman, L., “Wave Modeling of the Solar Wind”, <i>Living Reviews in Solar Physics</i>, vol. 7, no. 1, 2010. doi:10.12942/lrsp-2010-4.

        </a>
        </li>

        </ol>
      
        </Panel>


        <Panel header="Solar Prominences" key="8">
        <ol>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2018LRSP...15....7G/abstract" target="_blank">
        Gibson, S. E., “Solar prominences: theory and models. Fleshing out the magnetic skeleton”, <i>Living Reviews in Solar Physics</i>, vol. 15, no. 1, 2018. doi:10.1007/s41116-018-0016-2.

        </a>
        </li>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2012LRSP....9....2A/abstract" target="_blank">
        Arregui, I., Oliver, R., and Ballester, J. L., “Prominence Oscillations”, <i>Living Reviews in Solar Physics</i>, vol. 9, no. 1, 2012. doi:10.12942/lrsp-2012-2.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2014LRSP...11....1P/abstract" target="_blank">
        Parenti, S., “Solar Prominences: Observations”, <i>Living Reviews in Solar Physics</i>, vol. 11, no. 1, 2014. doi:10.12942/lrsp-2014-1.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2012LRSP....9....2A/abstract" target="_blank">
        Arregui, I., Oliver, R., and Ballester, J. L., “Prominence Oscillations”, <i>Living Reviews in Solar Physics</i>, vol. 9, no. 1, 2012. doi:10.12942/lrsp-2012-2.

        </a>
        </li>

        </ol>
        </Panel>

        <Panel header="Coronal Loop" key="9">
        <ol>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2014LRSP...11....4R/abstract" target="_blank">
        Reale, F., “Coronal Loops: Observations and Modeling of Confined Plasma”, <i>Living Reviews in Solar Physics</i>, vol. 11, no. 1, 2014. doi:10.12942/lrsp-2014-4.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2010LRSP....7....5R/abstract" target="_blank">
        Reale, F., “Coronal Loops: Observations and Modeling of Confined Plasma”, <i>Living Reviews in Solar Physics</i>, vol. 7, no. 1, 2010. doi:10.12942/lrsp-2010-5.

        </a>
        </li>


        </ol>
        </Panel>



        <Panel header="Waves & Oscillations" key="10">
        <ol>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2023LRSP...20....1J/abstract" target="_blank">
        Jess, D. B., Jafarzadeh, S., Keys, P. H., Stangalini, M., Verth, G., and Grant, S. D. T., “Waves in the lower solar atmosphere: the dawn of next-generation solar telescopes”, <i>Living Reviews in Solar Physics</i>, vol. 20, no. 1, 2023. doi:10.1007/s41116-022-00035-6.

        </a>
        </li>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2015LRSP...12....3W/abstract" target="_blank">
        Warmuth, A., “Large-scale Globally Propagating Coronal Waves”, <i>Living Reviews in Solar Physics</i>, vol. 12, no. 1, 2015. doi:10.1007/lrsp-2015-3.

        </a>
        </li>

        </ol>
        </Panel>

        <Panel header="Solar Wind" key="11">
        <ol>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2021LRSP...18....3V/abstract" target="_blank">
        Vidotto, A. A., “The evolution of the solar wind”, <i>Living Reviews in Solar Physics</i>, vol. 18, no. 1, 2021. doi:10.1007/s41116-021-00029-w.

        </a>
        </li>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2018AGUFMSH53A..07V/abstract" target="_blank">
        Verscharen, D., Klein, K. G., and Maruca, B., “The Multi-Scale Nature of the Solar Wind”, vol. 2018, 2018.
        </a>
        </li>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2018LRSP...15....4G/abstract" target="_blank">
        Gombosi, T. I., van der Holst, B., Manchester, W. B., and Sokolov, I. V., “Extended MHD modeling of the steady solar corona and the solar wind”, <i>Living Reviews in Solar Physics</i>, vol. 15, no. 1, 2018. doi:10.1007/s41116-018-0014-4.

        </a>
        </li>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2018LRSP...15....1R/abstract" target="_blank">
        Richardson, I. G., “Solar wind stream interaction regions throughout the heliosphere”, <i>Living Reviews in Solar Physics</i>, vol. 15, no. 1, 2018. doi:10.1007/s41116-017-0011-z.

        </a>
        </li>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2013LRSP...10....2B/abstract" target="_blank">
        Bruno, R. and Carbone, V., “The Solar Wind as a Turbulence Laboratory”, <i>Living Reviews in Solar Physics</i>, vol. 10, no. 1, 2013. doi:10.12942/lrsp-2013-2.

        </a>
        </li>
        

        </ol>
        </Panel>

        <Panel header="Coronal Mass Ejections" key="12">
        <ol>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2012LRSP....9....3W/abstract" target="_blank">
        Webb, D. F. and Howard, T. A., “Coronal Mass Ejections: Observations”, <i>Living Reviews in Solar Physics</i>, vol. 9, no. 1, 2012. doi:10.12942/lrsp-2012-3.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2011LRSP....8....1C/abstract" target="_blank">
        Chen, P. F., “Coronal Mass Ejections: Models and Their Observational Basis”, <i>Living Reviews in Solar Physics</i>, vol. 8, no. 1, 2011. doi:10.12942/lrsp-2011-1.

        </a>
        </li>

        
        </ol>
        </Panel>
        

        <Panel header="Machine learning in solar physics" key="13">
        <ol>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2023LRSP...20....4A/abstract" target="_blank">
        Asensio Ramos, A., Cheung, M. C. M., Chifu, I., and Gafeira, R., “Machine learning in solar physics”, <i>Living Reviews in Solar Physics</i>, vol. 20, no. 1, 2023. doi:10.1007/s41116-023-00038-x.
        </a>
        </li>

        </ol>
        </Panel>
        <Panel header="Flux Emergence" key="14">
        <ol>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2014LRSP...11....3C/abstract" target="_blank">
        Cheung, M. C. M. and Isobe, H., “Flux Emergence (Theory)”, <i>Living Reviews in Solar Physics</i>, vol. 11, no. 1, 2014. doi:10.12942/lrsp-2014-3.

        </a>
        </li>

        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2013LRSP...10....4L/abstract" target="_blank">
        Lockwood, M., “Reconstruction and Prediction of Variations in the Open Solar Magnetic Flux and Interplanetary Conditions”, <i>Living Reviews in Solar Physics</i>, vol. 10, no. 1, 2013. doi:10.12942/lrsp-2013-4.

        </a>
        </li>

        </ol>
        </Panel>
        <Panel header="Infrared Solar Physics" key="15">
        <ol>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2014LRSP...11....2P/abstract" target="_blank">
        Penn, M. J., “Infrared Solar Physics”, <i>Living Reviews in Solar Physics</i>, vol. 11, no. 1, 2014. doi:10.12942/lrsp-2014-2.
        </a>
        </li>

        </ol>
        </Panel>

        <Panel header="Space Weather" key="16">
        <ol>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2021LRSP...18....4T/abstract" target="_blank">
        Temmer, M., “Space weather: the solar perspective”, <i>Living Reviews in Solar Physics</i>, vol. 18, no. 1, 2021. doi:10.1007/s41116-021-00030-3.

        </a>
        </li>

        </ol>
        </Panel>
        <Panel header="Numerical Simulations" key="17">
        <ol>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2020LRSP...17....3L/abstract" target="_blank">
        Leenaarts, J., “Radiation hydrodynamics in simulations of the solar atmosphere”, <i>Living Reviews in Solar Physics</i>, vol. 17, no. 1, 2020. doi:10.1007/s41116-020-0024-x.

        </a>
        </li>

        </ol>
        </Panel>
        <Panel header="Others" key="18">
        <ol>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2016LRSP...13....3D/abstract" target="_blank">
        Desai, M. and Giacalone, J., “Large gradual solar energetic particle events”, <i>Living Reviews in Solar Physics</i>, vol. 13, no. 1, 2016. doi:10.1007/s41116-016-0002-5.
        </a>
        </li>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2016LRSP...13....2B/abstract" target="_blank">
        Basu, S., “Global seismology of the Sun”, <i>Living Reviews in Solar Physics</i>, vol. 13, no. 1, 2016. doi:10.1007/s41116-016-0003-4.
        </a>
        </li>
        <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2011LRSP....8....2R/abstract" target="_blank">
        Rimmele, T. R. and Marino, J., “Solar Adaptive Optics”, <i>Living Reviews in Solar Physics</i>, vol. 8, no. 1, 2011. doi:10.12942/lrsp-2011-2.

        </a>
        </li>
        </ol>
        </Panel>
        
      
      </Collapse>
    
    </>
  )
}

export default ReviewPapers

