export default function Participants({ data, isMobile }) {

    if (typeof data !== 'undefined') {
        return (
            <div id="workshop-participants">
                <style dangerouslySetInnerHTML={{ __html: "\n\t.s1 {\n\t\tcolor: #393573;\n\t\t/* background-color: bisque; */\n\t\tpadding-right: 1%;\n\t\t\n\t}\n\t#participants > div > table > tbody > tr:nth-child(even) {background-color: bisque;}\n\t\n" }} />
                <h1 id="participants">Participants</h1>
                <table>
                    <tbody  className="workshop-page-content">
                        <tr>
                            <th align="center" style={{ color: '#78004e' }} width={240} valign="middle">Name</th>
                            <th colSpan={6} style={{ color: '#78004e', padding: '10px', textAlign: 'center' }} width={240} valign="middle">
                                Affiliation</th>
                        </tr>
                        {data.participantlist.map((member) =>
                            <tr>
                                <td className="s1" dir="ltr">{member.name}</td>
                                <td className="s1">{member.affiliation}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                </div>
            // String(JSON.stringify(data))
                );
    } else {
        return null;
    }
  }