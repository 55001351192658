import React, { Children, useState} from "react";
import { Empty, List, Collapse, Switch } from "antd";
import parse, { attributesToProps } from "html-react-parser";

import "../Page.less";
import "./workshop.css"
import "./content.less"

import PageBreadcrumbs from "../Page";

import About from "./about";
import LOC from "./loc"
import SOC from "./scientific"
import Cover from "./cover"
import Coverf from "./coverf"
import Venue from "./venue"
import Participants from "./participants";
import Program from "./program";
import Resources from "./resources";
import Gallery from "./gallery";
import CustomTab from "./customTab";
import Poster from "./poster";
import Footer from "./footer";


function WorkshopPageContent({ data, isMobile, url }) { 
  const content={}
  const data_content=data.content;
  if (data_content.length > 0) {
    data_content.forEach(contentItem => {
      if (contentItem.__component === "workshop.cover") {
        content.coverData = contentItem;
      }
      if (contentItem.__component === "workshop.topbar") {
        content.topbarData = contentItem;
      } 
      if (contentItem.__component === "workshop.about") {
        content.aboutData = contentItem;
      } 
      if (contentItem.__component === "workshop.venue") {
        content.venueData = contentItem;
      } 
      if (contentItem.__component === "workshop.loc") {
        content.locData = contentItem;
      } 
      if (contentItem.__component === "workshop.soc") {
        content.socData = contentItem;
      } 
      if (contentItem.__component === "workshop.program") {
        content.programData = contentItem;
      } 
      if (contentItem.__component === "workshop.participants") {
        content.participantsData = contentItem;
      } 
      if (contentItem.__component === "workshop.resources") {
        content.resourcesData = contentItem;
      } 
      if (contentItem.__component === "workshop.gallery") {
        content.galleryData = contentItem;
      }
      if (contentItem.__component === "workshop.customtab") {
        content.customtabData = contentItem;
      } 
      if (contentItem.__component === "workshop.poster") {
        content.posterData = contentItem;
      } 
      if (typeof data.footer!='undefined') {
        content.footerData = data.footer;
      } 
      else return null; // any other component added to CMS but client doesn't yet know how to render it
    });
    return content;
  }
  else {
    return (
      <Empty description="No content! This page is under construction, please check back later." />
    );
  }
}


export default function WorkshopPage({ data, urlTitleMap, isMobile }) {
  const [selectedComponent, setSelectedComponent] = useState("About");

  const handleSelectionChange = (newComponent) => {
    setSelectedComponent(newComponent);
  }
  const content=WorkshopPageContent({data, isMobile, urlTitleMap});
  return (
    
    <div className="workshop-page-wrapper">
      <div className="workshop-page px-0 px-md-0" style={{ paddingTop: "0px" }}>
        {/*<PageBreadcrumbs data={data} urlTitleMap={urlTitleMap} />*/}
        <Cover data={content.coverData} isMobile={isMobile} />
        <div className="text-center">
        <div className="nav-pills">
          <button className={`nav-button ${content.aboutData?"":"d-none"} ${selectedComponent === "About" ? "active" : ""}`} onClick={()=> handleSelectionChange("About")}>About</button>
      <button className={`nav-button ${content.locData?"":"d-none"} ${selectedComponent === "LOC" ? "active":""}`} onClick={()=> handleSelectionChange("LOC")}>LOC</button>
      <button className={`nav-button ${content.socData?"":"d-none"} ${selectedComponent === "SOC" ? "active":""}`} onClick={()=> handleSelectionChange("SOC")}>SOC</button>
      <button className={`nav-button ${content.venueData?"":"d-none"} ${selectedComponent === "Venue" ? "active":""}`} onClick={()=> handleSelectionChange("Venue")}>Venue</button>
      <button className={`nav-button ${content.participantsData?"":"d-none"} ${selectedComponent === "Participants" ? "active":""}`} onClick={()=> handleSelectionChange("Participants")}>Participants</button>
      <button className={`nav-button ${content.programData?"":"d-none"} ${selectedComponent === "Program" ? "active":""}`} onClick={()=> handleSelectionChange("Program")}>Program</button>
      <button className={`nav-button ${content.resourcesData?"":"d-none"} ${selectedComponent === "Resources" ? "active":""}`} onClick={()=> handleSelectionChange("Resources")}>Resources</button>
      <button className={`nav-button ${content.galleryData?"":"d-none"} ${selectedComponent === "Gallery" ? "active":""}`} onClick={()=> handleSelectionChange("Gallery")}>Gallery</button>
      
      <button className={`nav-button ${content.posterData?"":"d-none"} ${selectedComponent === "Poster" ? "active":""}`} onClick={()=> handleSelectionChange("Poster")}>Poster</button>
      {content.customtabData.customcontent.map(({title,id})=>
      <button id={`customTab-${id}`} className={`nav-button ${content.customtabData?"":"d-none"} ${selectedComponent === `customTab-${id}` ? "active":""}`} onClick={()=> handleSelectionChange(`customTab-${id}`)}>
      {title}</button>)}
      </div>
      </div>
      <div id="content-page" >
        {selectedComponent === "About" && <About data={content.aboutData} isMobile={isMobile} />}
        {selectedComponent === "LOC" && <LOC data={content.locData} isMobile={isMobile} />}
        {selectedComponent === "SOC" && <SOC data={content.socData} isMobile={isMobile} />}
        {selectedComponent === "Venue" && <Venue data={content.venueData} isMobile={isMobile} />}
        {selectedComponent === "Participants" && <Participants data={content.participantsData} isMobile={isMobile} />}
        {selectedComponent === "Program" && <Program data={content.programData} isMobile={isMobile} />}
        {selectedComponent === "Resources" && <Resources data={content.resourcesData} isMobile={isMobile} />}
        {selectedComponent === "Gallery" && <Gallery data={content.galleryData} isMobile={isMobile} />}
        {content.customtabData.customcontent.map(({id, title, body})=>
        {
          if(selectedComponent === `customTab-${id}`) return <CustomTab id={id} title={title} data={body} isMobile={isMobile} />}
        // String(JSON.stringify(id))
        )}
        {selectedComponent === "Poster" && <Poster data={content.posterData} isMobile={isMobile} />}
      </div>
      <div id="workshop-footer">
      <Footer data={content.footerData} isMobile={isMobile} />
      </div>
    </div>
    </div>
  );
}

