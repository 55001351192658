import { Input, Space, Collapse, Typography,Col ,Row } from 'antd'
import React from 'react'
import { Pie , Column } from '@ant-design/plots';
import { FontSizeOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
const Title =Typography;
const PubPie = () => {
    const data = [
      {
        type: '2024',
        value: 2,
      },
      {
        type: '2023',
        value: 7,
      },
      {
        type: '2022',
        value: 7,
      },
      {
        type: '2021',
        value: 3,
      },
      {
        type: '2020',
        value: 1,
      },
      {
        type: '2019',
        value: 1,
      },
      {
        type: '2018',
        value: 6,
      },
      {
        type: '2017',
        value: 5,
      },
      {
        type: '2016',
        value: 2,
      },
    ];
    const config = {
      appendPadding: 10,
      data,
      angleField: 'value',
      colorField: 'type',
      radius: 0.9,
      label: {
        type: 'outer',
        content: '{name} {percentage}',
      },
      interactions: [
        {
          type: 'pie-legend-active',
        },
        {
          type: 'element-active',
        },
      ],
    };
    return <Pie {...config} />;
  };

  const liStyle = {
    fontFamily: "Arial",
    margin:15

};

const pieChartHeight = '360px'; // Set your desired pie chart height

const Publications = () => {
  return (
    <>
        
        <Title style={ {  textAlign: 'center', margin:'10px 0' }} ><h4>Aditya L1 Publications</h4></Title>

              <div style={{ height: pieChartHeight }}>
              <PubPie />
              </div>

        <Collapse accordion defaultActiveKey={['1']} >
        <Panel header="2024" key="9">
            <ol>
            <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2024ExA....57....7M/abstract" target="_blank"> Mishra, S., “Calibration of VELC detectors on-board Aditya-L1 mission”, <i>Experimental Astronomy</i>, vol. 57, no. 2, Springer, 2024. doi:10.1007/s10686-024-09922-2.

                </a>
            </li> 
            <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2024SoPh..299...32V/abstract" target="_blank"> Varma, M., “Correction to: The Solar Ultra-Violet Imaging Telescope (SUIT) Onboard Intelligence for Flare Observations”, <i>Solar Physics</i>, vol. 299, no. 3, 2024. doi:10.1007/s11207-024-02277-z.

                </a>
            </li> 

            </ol>

        </Panel>

        <Panel header="2023" key="1">
          
            <ol>
            <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2023JATIS...9d4001B/abstract" target="_blank"> Budihal, R. P., “Ground calibration of visible emission line coronagraph on board Aditya-L1 mission”, <i>Journal of Astronomical Telescopes, Instruments, and Systems</i>, vol. 9, 2023. doi:10.1117/1.JATIS.9.4.044001.

                </a>
            </li>
            <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2023AcAeJ...1c..70S/abstract" target="_blank"> Sowmya, G., “Technical Overview and Prospect of India's First Solar Mission - Aditya L1 Spacecraft”, <i>Acceleron Aerospace Journal</i>, vol. 1, no. 3, pp. 3.70, 2023. doi:10.61359/11.2106-2315.

                </a>
            </li>
            <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2023JAI....1250011N/abstract" target="_blank"> Narra, V. S., “Calibration of Spectropolarimetry Package for Visible Emission Line Coronagraph (VELC) on Board Aditya-L1 Mission”, <i>Journal of Astronomical Instrumentation</i>, vol. 12, no. 4, 2023. doi:10.1142/S2251171723500113.

                </a>
            </li>
            <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2023IAUS..372...17T/abstract" target="_blank"> Tripathi, D., “The Aditya-L1 mission of ISRO”, in <i>The Era of Multi-Messenger Solar Physics</i>, 2023, vol. 372, pp. 17–27. doi:10.1017/S1743921323001230.

                </a>
            </li>
            <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2023arXiv230703173P/abstract" target="_blank">  Priyal, M., “Data processing of Visible Emission Line Coronagraph Onboard ADITYA L1”, <i>arXiv e-prints</i>, 2023. doi:10.48550/arXiv.2307.03173.
                </a>
            </li>
            <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2023SoPh..298...16V/abstract" target="_blank">  Varma, M., “The Solar Ultra-Violet Imaging Telescope (SUIT) Onboard Intelligence for Flare Observations”, <i>Solar Physics</i>, vol. 298, no. 2, 2023. doi:10.1007/s11207-023-02108-7.
                </a>
                
            </li>
            <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2023RASTI...2..256V/abstract" target="_blank">  Varma, S. V. M., “The Solar Ultraviolet Imaging Telescope: detector characterization and readout electronics testing”, <i>RAS Techniques and Instruments</i>, vol. 2, no. 1, pp. 256–263, 2023. doi:10.1093/rasti/rzad013.
                </a>
                
            </li> 
            </ol>         
                   	
      </Panel>
      <Panel header="2022" key="2">
        
            <ol>
            <li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2022arXiv221213046T/abstract" target="_blank">
	 Tripathi, D., “The Aditya-L1 mission of ISRO”, <i>arXiv e-prints</i>, 2022. doi:10.48550/arXiv.2212.13046.
	</a>
  
</li>


<li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2022AGUFMSH23A..07R/abstract" target="_blank">
	 Roy, S., “Solar Ultraviolet Imaging Telescope (SUIT) forward modeling”, vol. 2022, 2022.
</a>
</li>

<li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2022JATIS...8d4004N/abstract" target="_blank">
	 Narra, V. S., “Optical metrology on primary mirror of visible emission line coronagraph on board Aditya-L1 mission”, <i>Journal of Astronomical Telescopes, Instruments, and Systems</i>, vol. 8, 2022. doi:10.1117/1.JATIS.8.4.044004.
</a>
</li>

<li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2022SPIE12181E..3OG/abstract" target="_blank">
	 Ghosh, A., “The thermal filter for the Solar Ultraviolet Imaging Telescope (SUIT) on-board Aditya-L1”, in <i>Society of Photo-Optical Instrumentation Engineers (SPIE) Conference Series</i>, 2022, vol. 12181. doi:10.1117/12.2629760.
</a>
</li>

<li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2022AdSpR..69.2601S/abstract" target="_blank">
	 Singh, J., “Data pipeline architecture and development for VELC onboard Space Solar Mission AdityaL1”, <i>Advances in Space Research</i>, vol. 69, no. 6, pp. 2601–2610, 2022. doi:10.1016/j.asr.2021.12.044.
</a>
</li>


<li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2022AdSpR..69..814S/abstract" target="_blank">
	 Sasikumar Raja, K., Venkata, S., Singh, J., and Raghavendra Prasad, B., “Solar coronal magnetic fields and sensitivity requirements for spectropolarimetry channel of VELC onboard Aditya-L1”, <i>Advances in Space Research</i>, vol. 69, no. 1, pp. 814–822, 2022. doi:10.1016/j.asr.2021.10.053.
</a>
</li>

<li style={liStyle} ><a href="https://ui.adsabs.harvard.edu/abs/2022ExA....53...71V/abstract" target="_blank">
	 Venkata, S., Prasad B, R., and Singh, J., “Spectropolarimetry package for visible emission line coronagraph (VELC) on board Aditya-L1 mission”, <i>Experimental Astronomy</i>, vol. 53, no. 1, pp. 71–82, 2022. doi:10.1007/s10686-021-09816-7.
</a>
</li>
</ol>
        
      </Panel>
      <Panel header="2021" key="3">
      <ol>
          
      <li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2021ApOpt..60.8145N/abstract" target="_blank" >
 Nagaraju, K., “Spectropolarimeter on board the Aditya-L1: polarization modulation and demodulation”, <i>Applied Optics</i>, vol. 60, no. 26, p. 8145, 2021. doi:10.1364/AO.434219.
</a>
</li>

<li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2021OptEn..60g4103V/abstract" target="_blank" >
 Venkata, S. and Budihal, R. P., “Light scattering due to particulate contamination over the primary mirror of Visible Emission Line Coronagraph on board Aditya-L1 mission”, <i>Optical Engineering</i>, vol. 60, 2021. doi:10.1117/1.OE.60.7.074103.

</a>
</li>
<li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2021FrASS...8...88P/abstract" target="_blank" >
 Patel, R., “Characterizing Spectral Channels of Visible Emission Line Coronagraph of Aditya-L1”, <i>Frontiers in Astronomy and Space Sciences</i>, vol. 8, 2021. doi:10.3389/fspas.2021.660992.

</a>
</li>
      </ol>
      </Panel>


      <Panel header="2020" key="4">
        <ol>
      <li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2020ExA....50..265N/abstract " target="_blank" >
 Narra, V. S., “Scatter due to Ultraviolet (UV) photopolymerization of molecular contamination on optical surfaces”, <i>Experimental Astronomy</i>, vol. 50, no. 2–3, pp. 265–287, 2020. doi:10.1007/s10686-020-09675-8.
</a>
</li>
</ol>
      </Panel>
      <Panel header="2019" key="5">
      <ol>
      <li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2019AdSpR..64.1455S/abstract" target="_blank" >
       Singh, J., Prasad, B. R., Venkata, S., and Kumar, A., “Exploring the outer emission corona spectroscopically by using Visible Emission Line Coronagraph (VELC) on board ADITYA-L1 mission”, <i>Advances in Space Research</i>, vol. 64, no. 7, pp. 1455–1464, 2019. doi:10.1016/j.asr.2019.07.007.
      </a> 
      </li>
      </ol>
      </Panel>
      <Panel header="2018" key="6">
        <ol>
        <li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2018P%26SS..163...42G/abstract" target="_blank" >
 Goyal, S. K., “Aditya Solarwind Particle EXperiment (ASPEX) onboard the Aditya-L1 mission”, <i>Planetary and Space Science</i>, vol. 163, pp. 42–55, 2018. doi:10.1016/j.pss.2018.04.008.
</a> </li>

<li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2018IAUS..335..340B/abstract" target="_blank" >
 Banerjee, D., Patel, R., Pant, V., and ADITYA Team, “The Inner Coronagraph on Board ADITYA-L1 and Automatic Detection of CMEs”, in <i>Space Weather of the Heliosphere: Processes and Forecasts</i>, 2018, vol. 335, pp. 340–343. doi:10.1017/S1743921317008584.

</a> </li>
<li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2018SoPh..293..103P/abstract" target="_blank" >
 Patel, R., Amareswari, K., Pant, V., Banerjee, D., Sankarasubramanian, K., and Kumar, A., “Onboard Automated CME Detection Algorithm for the Visible Emission Line Coronagraph on ADITYA-L1”, <i>Solar Physics</i>, vol. 293, no. 7, 2018. doi:10.1007/s11207-018-1323-4.
</a> </li>
<li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2018ExA....45..219R/abstract" target="_blank" >
 Raj Kumar, N., Raghavendra Prasad, B., Singh, J., and Venkata, S., “Optical design of visible emission line coronagraph on Indian space solar mission Aditya-L1”, <i>Experimental Astronomy</i>, vol. 45, no. 2, pp. 219–229, 2018. doi:10.1007/s10686-017-9569-7.

</a> </li>

<li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2018IAUS..340..171P/abstract" target="_blank" >
 Patel, R., Amareswari, K., Pant, V., Banerjee, D., and Sankarasubramanian, K., “Automated detection of Coronal Mass Ejections in Visible Emission Line Coronagraph (VELC) on-board ADITYA-L1”, in <i>Long-term Datasets for the Understanding of Solar and Stellar Magnetic Cycles</i>, 2018, vol. 340, pp. 171–172. doi:10.1017/S174392131800193X.
</a> 
</li>
<li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2018AdSpR..61..749Y/abstract" target="_blank" >
 Yadav, V. K., Srivastava, N., Ghosh, S. S., Srikar, P. T., and Subhalakshmi, K., “Science objectives of the magnetic field experiment onboard Aditya-L1 spacecraft”, <i>Advances in Space Research</i>, vol. 61, no. 2, pp. 749–758, 2018. doi:10.1016/j.asr.2017.11.008.
</a> </li>
        </ol>
      </Panel>
      <Panel header="2017" key="7">
      <ol>
      <li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2017CSci..113..625S/abstract " target="_blank" >
 Sankarasubramanian, K., “X-ray spectrometers on-board Aditya-L1 for solar flare studies”, <i>Current Science</i>, vol. 113, no. 4, p. 625, 2017. doi:10.18520/cs/v113/i04/625-627.
</a> </li>
<li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2017CSci..113..613R/abstract " target="_blank" >
 Raghavendra Prasad, B., “Visible Emission Line Coronagraph on Aditya-L1”, <i>Current Science</i>, vol. 113, no. 4, p. 613, 2017. doi:10.18520/cs/v113/i04/613-615.
</a> </li>
<li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2017CSci..113..610S/abstract " target="_blank" >
 Seetha, S. and Megala, S., “Aditya-L1 mission”, <i>Current Science</i>, vol. 113, no. 4, p. 610, 2017. doi:10.18520/cs/v113/i04/610-612.
</a> </li>
<li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2017CSci..113..620J/abstract" target="_blank" >
 Janardhan, P., “Probing the heliosphere using in situ payloads on-board Aditya-L1”, <i>Current Science</i>, vol. 113, no. 4, p. 620, 2017. doi:10.18520/cs/v113/i04/620-624.
</a> </li>
<li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2017JATIS...3a4002V/abstract " target="_blank" >
 Venkata, S. N., Prasad, B. R., Nalla, R. K., and Singh, J., “Scatter studies for visible emission line coronagraph on board ADITYA-L1 mission”, <i>Journal of Astronomical Telescopes, Instruments, and Systems</i>, vol. 3, 2017. doi:10.1117/1.JATIS.3.1.014002.
</a> </li>
       </ol>
      </Panel>


      <Panel header="2016" key="8">
      <ol>
      <li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2016SPIE.9905E..4NG/abstract" target="_blank" >
 Goyal, S. K., “Multi-directional measurements of high energy particles from the Sun-Earth L1 point with STEPS”, in <i>Space Telescopes and Instrumentation 2016: Ultraviolet to Gamma Ray</i>, 2016, vol. 9905. doi:10.1117/12.2232259.
</a> </li>
<li style={liStyle}><a href="https://ui.adsabs.harvard.edu/abs/2016SPIE.9905E..03G/abstract" target="_blank" >
 Ghosh, A., “The Solar Ultraviolet Imaging Telescope onboard Aditya-L1”, in <i>Space Telescopes and Instrumentation 2016: Ultraviolet to Gamma Ray</i>, 2016, vol. 9905. doi:10.1117/12.2232266.
</a> </li>
      </ol>
      </Panel>
    </Collapse>
    
    </>
  )
}

export default Publications
