export default function Resources({ data, isMobile }) {
    if (typeof data !== 'undefined') {
            
        return (
        <div>
            {data.resourcesitem.map(({title,url,active} )=>
                <div>     
                    <h2 className="text-center"> {title}</h2>
                    <div className="text-center">
                        {url && active?<iframe title={title} src={url} style={{width: '80vw', height: '300px', border: 0}}></iframe>:<span>link not working prperly</span>}
                    </div> 
                </div>
                    )}
                
        </div>
        
        // String(JSON.stringify(data))
    );
} else {
    return null;
}
}

//   