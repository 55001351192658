export default function CustomTab({ id,title, data, isMobile }) {

    if (typeof data !== 'undefined') {
          
          return (
            
            <div unique="false">
              <h2>{title}</h2>
          <div className="py-2 mx-auto" />
          
           
            <div className="workshop-page-content">
            
            <br />
            <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div dangerouslySetInnerHTML={{ __html: data }}></div>
                </div>
            </div>
            <br /> 
          </div> 
          
        </div>
            // String(JSON.stringify(data))
        );
    } else {
        return null;
    }
}

//   {"__component":"workshop.customtab","id":3,"customcontent":[{"id":4,"title":"New Tab","body":"<p>My New Tab Content</p>"}]}
