
function Venue({ data, isMobile }) {
    if(typeof data !== 'undefined'){
        return (
            <div dangerouslySetInnerHTML={{ __html: data.body }}></div>
        );
    } else {
        return null;
    }
  }
  
  export default Venue;
  