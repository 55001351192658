import React from "react";
export default function Cover({data, isMobile}){
    const venue=data.venue;
    const dates=data.dates;
    const img=data.image;
    const caption=data.caption;
    const title=data.title;
    
          return (
      
            <div className="cover-workshop text-white rounded container-workshop" style={{backgroundImage: `url(${img.url})`, backgroundPositionY: '40%', backgroundPositionX: 'center', opacity: '.89'}}>
              <div className="d-flex h-100">
                <div className="cover-container d-flex w-100 h-100 mx-auto flex-column">
                  <header className="mb-auto">
                    <div>
                      <nav className="nav nav-masthead d-flex justify-content-end">
                        <a className="nav-link social" aria-current="page" href="https://fb.com/AdityaL1SC" target="”_blank”"><i className="bi bi-facebook" /></a>
                        <a className="nav-link social" href="https://twitter.com/AdityaL1SC" target="”_blank”"><i className="bi bi-twitter" /></a>
                      </nav>
                    </div>
                  </header>   
                  <div className>
                    <div className=" row text-center m-0">
                      <div className="col-3 my-auto">
                        {/* <img class="img-fluid" src="{{images}}/logos/iit_logo_original.png" width="50vh" /> */}
                      </div>
                      {/*<div className="col-6  d-none d-sm-block">
                        <h1 className="cover-name rounded-pill"> Aditya-L1 Support Cell Workshop</h1>
                      </div>*/}
                      <div className="col-6">
                        <h1 className="cover-name rounded-pill">{title}</h1>
                        <br /><br />
                      </div>
                      </div> 
                     
                   <div className="row text-center m-0">
                      <div className="col-3 my-auto">
                        {/* <img class="img-fluid" src="{{images}}/logos/iit_logo_original.png" width="50vh" /> */}
                      </div>
                      <div className="col-6">
                        <h3 className="cover-name rounded-pill">@ {venue}<br/>{dates}</h3>
                        <br /><br />
                      </div>
                    </div>                      

                  </div>
                </div>
                <div>
                </div>
              </div>
              <div className="sticker bottom-right-bg " />
              <h5 className="bottom-right text-center" style={{fontSize: '0.5em'}}>{venue}<br />
               {dates}</h5>
              <div className="bottom-left text-white" style={{fontSize: '0.5em'}}>Image: {caption}</div>
            </div>
          );
        }
