import React from 'react';
import {  Typography } from 'antd'
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'; // Import the CSS for styling


const localizer = momentLocalizer(moment) // or globalizeLocalizer


const EventCalendar = () => {

  const Title =Typography;

  const handleEventClick = (event) => {
    if (event.url) {
      window.open(event.url, '_blank'); // Open the link in a new tab
    }
  };

    const events = [

	 {
        title: "Probing the Sun with India\'s First Solar Space Mission: Aditya-L1",
        start: new Date(2023, 9, 10, 16, 0), // Date and time when the event starts
        end: new Date (2023, 9, 10, 18, 0),   // Date and time when the event ends
       
         },



     	 {
        title: "India\'s first Space based Solar Mission Aditya-L1",
        start: new Date(2023, 9, 9, 14, 0), // Date and time when the event starts
        end: new Date (2023, 9, 9, 16, 0),   // Date and time when the event ends
   
     	 },


    	  {
        title: 'Our Sun with Aditya-L1',
        start: new Date(2023, 9, 9, 10, 0), // Date and time when the event starts
        end: new Date (2023, 9, 9, 12, 0),   // Date and time when the event ends
     	 },

        {
          title: 'Aditya-L1 Support Cell Fifth Workshop at IIT Kanpur',
          start: new Date(2023, 8, 29, 9, 0), // Date and time when the event starts
          end: new Date (2023, 9, 1, 18, 0),   // Date and time when the event ends
          url:'https://al1ssc.aries.res.in/workshop-5',

        },

        {
          title: 'Technical Talk on Solar Data Analysis using AI/ML based Techniques at IIT BHU',
          start: new Date(2023, 8, 27, 11, 0), 
          end: new Date (2023, 8, 27, 17, 0),   
        },

        {
          title: 'Popular Talk on Aditya-L1 Mission- A journey towards the sun at Birla Industrial and Technological Museums',
          start: new Date(2023, 8, 6, 11, 0), 
          end: new Date (2023, 8, 6, 15, 0),   
        },

        {
          title: 'India \'s own Aditya L1 Mission at Nehru Planetarium Prime Minister Museum & Library ',
          start: new Date(2023, 8, 4, 9, 0), 
          end: new Date (2023, 8, 4, 13, 0),   
        },

        {
          title: 'Aditya L1 Mission Talk at Tamil Nadu Science and Technology Centre, Ariviyal Palagai ',
          start: new Date(2023, 8, 3, 11, 0), 
          end: new Date (2023, 8, 3, 15, 0),   
        },

        {
          title: 'Aditya-L1 Support Cell Fourth Workshop at ARIES Nainital',
          start: new Date(2023, 5, 28, 9, 0), // Date and time when the event starts
          end: new Date (2023, 6, 7, 18, 0),   // Date and time when the event ends
          url:'https://al1ssc.aries.res.in/workshop-4',

        },

        {
          title: 'A Public lecture on Probing our Nearest Star through Aditya-L1 Mission ',
          start: new Date(2023, 2, 15, 17, 0), 
          end: new Date (2023, 2, 15, 21, 0),   
        },

        {
          title: 'Aditya-L1 Support Cell Third Workshop at IIT BHU',
          start: new Date(2023, 1, 25, 9, 0), // Date and time when the event starts
          end: new Date (2023, 1, 27, 18, 0),   // Date and time when the event ends
          url:'https://al1ssc.aries.res.in/workshop-3',

        },
        {
          title: 'Aditya-L1 Support Cell Second Workshop at MAHE, Manipal',
          start: new Date(2022, 10, 27, 9, 0), // Date and time when the event starts
          end: new Date (2022, 10, 30, 18, 0),   // Date and time when the event ends
          url:'https://al1ssc.aries.res.in/workshop-2',

        },
        {
          title: 'Aditya-L1 Support Cell First Workshop at ARIES, Nainital',
          start: new Date(2022, 5, 27, 9, 0), // Date and time when the event starts
          end: new Date (2022, 6, 6, 18, 0),   // Date and time when the event ends
          url:'https://al1ssc.aries.res.in/workshop-1',

        },

      ];
    

  return (
    <div>

    <Title style={ {  textAlign: 'center', margin:'10px 0' }} ><h4>Aditya L1 Events Calendar</h4></Title>
    <p>For event addition, related to Aditya-L1. Please fill out this <a href="https://forms.gle/dvfa4DiUahBwgJJb7" target="_blank
    ">form </a></p>
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      onSelectEvent={handleEventClick} // Handle event click
      style={{  height: '600px', }} // Set the calendar height
    />

    </div>
      
  )
}

export default EventCalendar
