
function LOC({ data, isMobile }) {
  if(typeof data !== 'undefined'){
      return (
        <div id="workshop-loc">
          <div className="py-2" />
          <div>
            <h2>LOCAL ORGANISING <span>COMMITTEE</span></h2>
            <br />
            <div className="row">
              {data.LOC.map((member)=>
                <div className="col-lg-4">
                  <p>{member.Name}<br /><span style={{fontStyle: 'italic', color: '#686868', fontSize: '18px'}}>({member.Affiliation})</span></p>
                </div>
              )}
            </div>
            <br /> 
          </div>
        </div>
      );
  } else {
      return null;
  }
}

export default LOC;
