import CarouselContent from "./Feature2_Workshop"
function About({ data, isMobile }) {

  // const children = [
  
  //   <About
  //     id="about"
  //     key="workshop-about"
  //     dataSource={data.about}
  //     dataSource_carousel={data.about.carousel}
  //     isMobile={isMobile}
  //   />,
    // <Carousel
    //   id="loc"
    //   key="workshop-loc"
    //   dataSource={data.loc}
    //   isMobile={isMobile}
    // />
  // ];
  return (
    <div id="about-workshop">
      <h1>{data.title}</h1>
      <div className="row" data-name="about">
        <div className="container" style={{ display: "flex", flexDirection: "row" }}>
          <div className="col-md-8 col-xs-12" style={{ order: 1 }} dangerouslySetInnerHTML={{ __html: data.body }}></div>
          <div className="col-md-4 col-xs-12" style={{ order: 2 }}>
          

{/* const Carousel = ({ data }) => {
  return ( */}
    {/* <div id="carouselVenueIndicator" className="carousel slide" data-ride="carousel">
      <ol className="carousel-indicators">
        {data.carousel.map((item, index) => (
          <li key={index} data-target="#carouselVenueIndicator" data-slide-to={index} className={index === 0 ? "active" : ""}></li>
        ))}
      </ol>
      <div className="carousel-inner">
        {data.carousel.map((item, index) => (
          <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
            <img src={item.image.url} className="d-block w-100" style={{ height: "500px", objectFit: "cover" }} alt="Image" />
          </div>
        ))}
      </div>
      <a className="carousel-control-prev" href="#carouselVenueIndicator" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href="#carouselVenueIndicator" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div> */}
  {/* );
}; */}

            <div id="carouselVenue" className="carousel slide w-100 h-100" data-bs-ride="carousel" style={{display: 'block'}}>
              <CarouselContent
                id="about-carousel" 
                key="workshop-about"
                dataSource={data.carousel}
                dataSource_carousel={data.carousel}
                isMobile={isMobile}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default About